<template>
	<svg :height="height" :width="width" />
</template>

<script>
	import * as d3 from "d3";

	export default {
		name: "Indicator",
		props: ['height', 'width', 'widgetData'],
		data(){
			return {
				svg: null
			}
		},
		computed: {
			val(){
				return this.widgetData.items[0].values[1]
			}
		},
		watch: {
			val(){
				this.initSvg()
			},
			width(){
				this.initSvg()
			}
		},
		methods: {
			initSvg() {
				this.svg = d3.select(this.$el)
				this.svg.selectAll('g').remove()

				let colors = ["#00aa00", "#FF8800", "#aa0000"]
				let thresholds = [this.widgetData.params.ok, this.widgetData.params.warning, this.widgetData.params.error ]
				let reverse = this.widgetData.params.ok > this.widgetData.params.error
				let percent = 1
				let currentColor = colors[1]
				let targetColor = colors[2]

				for(let i = 0; i < thresholds.length; i++){
					if(reverse){
						if(this.val >= thresholds[i]) {
							currentColor = colors[i]
							targetColor = colors[i-1] ? colors[i-1] : colors[i]
							percent = Math.min(parseInt(this.val) / (thresholds[i-1] ? thresholds[i-1] : thresholds[i]), 1)

							break;
						}
					}
					else {
						if(this.val <= thresholds[i]) {
							currentColor = colors[i]
							targetColor = colors[i+1] ? colors[i+1] : colors[i]
							percent = Math.min(parseInt(this.val) / (thresholds[i+1] ? thresholds[i+1] : thresholds[i]), 1)

							break;
						}
					}
				}

				let color = this.$tools.pSBC(percent, currentColor,  targetColor, true)

				let color2 = this.$tools.pSBC(0.5, color, this.$tools.pSBC(0.5, color, '#ffffff', true), true)
				let color3 = this.$tools.pSBC(0.2, color2, '#ffffff', true)

				let w = Math.min(this.width, this.height) / 2.4
				let viewport = this.svg.append('g')

				viewport.attr("transform", `translate(${this.width / 2}, ${this.height / 2})`)

				let light = viewport.append('g')

				let id = Math.random().toString(36).slice(2);

				viewport.append("clipPath")
					.attr("id", id)
					.append('circle')
					.attr('r', w * 0.8)

				let gradient = light.append('defs')
					.append('radialGradient')
					.attr('id', "grad" + id)
					.attr('x2', '0%')
					.attr('y1', '100%')
					.attr('y2', '0%')

				gradient.append('stop')
					.attr('offset', '55%')
					.attr('stop-color', color)

				gradient.append('stop')
					.attr('offset', '70%')
					.attr('stop-color', color2)

				light.append('circle')
					.attr('r', w)
					.attr('class', 'shadow2')
					.attr('fill', 'var(--v-background-base)')
					.attr('filter', 'url(#f2)')

				light.append('circle')
					.attr('r', w)
					.attr('fill', "url(#grad" + id + ")")
					.attr("clip-path", "url(#" + id + ")")

				let nb_lines = 7

				for (let i = 0; i < this.width / nb_lines; i++) {
					light.append('rect')
						.attr('fill', color2)
						.attr('class', 'shadow')
						.attr('x', -w)
						.attr('y', -w + i * nb_lines)
						.attr('width', this.width)
						.attr('height', 4)
						.attr("clip-path", "url(#" + id + ")")
						.attr('opacity', 0.3)

					light.append('rect')
						.attr('fill', color2)
						.attr('class', 'shadow')
						.attr('y', -w)
						.attr('x', -w + i * nb_lines)
						.attr('height', this.width)
						.attr('width', 4)
						.attr("clip-path", "url(#" + id + ")")
						.attr('opacity', 0.3)
				}

				light.append('text')
					.text(this.val)
					.attr("font-size", w / 1.2)
					.attr('opacity', '0.9')
					.attr('fill', color3)
					.attr("style", "text-anchor: middle; dominant-baseline: central; font-weight: bold; text-shadow: 0 0 2px rgba(255,255,255, 0.5)")
			}
		},
		mounted(){
			this.initSvg()
		}
	}
</script>

<style scoped>
	svg >>> .shadow {
		-webkit-filter: drop-shadow( 0 0 2px rgba(255,255,255, 0.5));
		filter: drop-shadow( 0 0 2px rgba(255,255,255,0.0));
		/* Similar syntax to box-shadow */
	}
	svg >>> .shadow2 {
		-webkit-filter: drop-shadow( 0 0 2px rgba(0,0,0,0.5));
		filter: drop-shadow( 0 0 2px rgba(0,0,0,0.5));
	}
</style>